export const FirebaseConfig = {
	"projectId": "besti-ride",
	"appId": "1:365302599440:web:2a989b2a6141b61f381f95",
	"databaseURL": "https://besti-ride-default-rtdb.firebaseio.com",
	"storageBucket": "besti-ride.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAANr85auDkm99nTfd5ar7gqEJNwv--sTg",
	"authDomain": "besti-ride.firebaseapp.com",
	"messagingSenderId": "365302599440",
	"measurementId": "G-13MRS8RP9E"
};